<template>
    <vs-sidebar absolute :open="activeSidebar">
        <template #logo>
            <div class="row">
                <div class="col-8 d-flex justify-content-end">
                    <router-link to="/app" rel="Home" class="btn btn-link p-0">
                        <img
                            src="/img/logos/complete_logo_and_pet.png"
                            alt="Portal"
                            title="Logo completo"
                            loading="lazy"
                            height="60"
                            width="100"
                            class="max-60"
                        />
                    </router-link>
                </div>
                <div class="col-4 d-flex justify-content-end">
                    <vs-button
                        color="#ee3f59"
                        circle
                        icon
                        size="xl"
                        aria-label="Cerrar Sidebar"
                        @click="UPDATED_SIDEBAR_STATE(false)"
                    >
                        <img
                            src="@/assets/images/close.svg"
                            height="20"
                            width="20"
                            alt="close menu"
                        />
                    </vs-button>
                </div>
            </div>
        </template>
        <fragment
            v-for="field in sidebarItems"
            :id="field.name"
            :key="field.name"
        >
            <vs-sidebar-group v-if="field.type === 'group'" :id="field.id">
                <template #header>
                    <vs-sidebar-item arrow>
                        <template #icon>
                            {{ field.icon }}
                        </template>
                        <span>
                            {{ field.name }}
                        </span>
                    </vs-sidebar-item>
                </template>
                <vs-sidebar-item
                    v-for="item in field.items"
                    :id="item.id"
                    :key="item.name"
                    :to="item.url"
                >
                    <template #icon> 🔷 </template>
                    <span>{{ item.name }}</span>
                </vs-sidebar-item>
            </vs-sidebar-group>

            <vs-sidebar-item v-else :id="field.name" :to="field.url">
                <template #icon> {{ field.icon }} </template>
                <span>{{ field.name }}</span>
            </vs-sidebar-item>
        </fragment>
        <template #footer>
            <div class="d-flex flex-column">
                <div
                    v-for="(item, index) in rolesComponent"
                    :key="index"
                    class="d-flex align-items-center my-1"
                >
                    <vs-avatar
                        color="#d3d4f9"
                        :badge="index === 0"
                        badge-color="danger"
                        size="30"
                    >
                        {{ item.emoji }}
                    </vs-avatar>
                    <strong class="ml-3 text-capitalize">
                        {{ item.name }}
                    </strong>
                </div>
            </div>
        </template>
    </vs-sidebar>
</template>

<script>
import { mapMutations, mapState } from "vuex";

import { rolesEmoji } from "@/common/fields/roles";

export default {
    name: "SidebarApp",
    data: () => ({
        active: "home",
        activeSB: false,
        rolesComponent: []
    }),
    computed: {
        ...mapState("control", ["activeSidebar", "sidebarItems"]),
        ...mapState("auth", ["roles"]),
        path() {
            return this.$route.path;
        }
    },
    watch: {
        path(value) {
            const path = value.split("/app");
            if (path.length > 2) {
                this.active = path[2];
            }
        }
    },
    mounted() {
        if (this.roles.length > 0) {
            this.rolesComponent = this.roles.map((item) => ({
                name: item.slug,
                emoji: rolesEmoji[item.slug] || "👷‍♂️"
            }));
        }
    },
    methods: {
        ...mapMutations("control", ["UPDATED_SIDEBAR_STATE"])
    }
};
</script>

<style scoped>
.max-60 {
    max-height: 50px !important;
}
</style>
