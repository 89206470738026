<template>
    <div class="d-block">
        <vs-navbar v-model="active" padding-scroll shadow>
            <template #left>
                <vs-button
                    circle
                    icon
                    size="xl"
                    class="ml-0"
                    @click="UPDATED_SIDEBAR_STATE(!activeSidebar)"
                >
                    <img
                        src="@/assets/images/hamburger.svg"
                        height="20"
                        width="20"
                        alt="open menu"
                    />
                </vs-button>

                <router-link to="/app" aria-label="Inicio">
                    <picture>
                        <source
                            srcset="/img/logos/complete_logo.png"
                            class="h-10 w-auto"
                            width="180"
                            height="113"
                            media="(min-width:900px)"
                            x-content-type-options="nosniff"
                        />
                        <img
                            src="/img/logos/logo.png"
                            :alt="`Icono ${$siteTitle}`"
                            :title="`Icono ${$siteTitle}`"
                            class="h-10 w-auto"
                        />
                    </picture>
                </router-link>
            </template>
            <merchant-modal-selector class="d-lg-none" />
            <template #right>
                <div
                    class="d-flex w-100 justify-content-end align-items-center"
                >
                    <merchant-modal-selector class="d-none d-lg-block px-1" />
                    <bell-notifications />
                    <options-user
                        v-if="$data.$showUserMenu"
                        class="ml-lg-2 ml-1"
                    />
                </div>
            </template>
        </vs-navbar>
    </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

import { ENABLED_AUTH } from "@/common/constants";

import MerchantModalSelector from "../merchants/MerchantModalSelector.vue";
import BellNotifications from "../notifications/BellNotifications.vue";
import OptionsUser from "./OptionsUser.vue";

export default {
    name: "NavbarApp",
    components: { OptionsUser, MerchantModalSelector, BellNotifications },
    data: () => ({
        active: true,
        $showUserMenu: ENABLED_AUTH
    }),
    computed: {
        ...mapState("control", ["activeSidebar"])
    },
    methods: {
        ...mapMutations("control", ["UPDATED_SIDEBAR_STATE"])
    }
};
</script>
