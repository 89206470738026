<template>
    <div>
        <vs-tooltip v-model="show" bottom shadow not-hover>
            <template #tooltip>
                <div
                    v-if="notifications.length"
                    class="d-flex flex-column align-items-start"
                >
                    <div
                        v-for="(value, index) in notifications"
                        :key="index"
                        class="d-flex pl-1 pr-2 mb-2 btn-a d-flex flex-column"
                        @click="routePush(value.id, value.metadata.url)"
                    >
                        <span class="h5 text-left">
                            <span v-if="value.checkedAt"> ⚪ </span>
                            <span v-else> 🔵 </span>
                            {{ value.metadata.title }}
                        </span>
                        <div class="text-justify w-100">
                            <span>
                                {{ value.metadata.message }}
                            </span>
                        </div>
                    </div>
                </div>
                <div v-else class="p-2">
                    <span> No hay notificaciones </span>
                </div>
            </template>
            <vs-avatar
                circle
                :badge="!!notificationNumber"
                badge-color="#f07325"
                badge-position="top-right"
                pointer
                color="#02913f"
                size="40"
                @click="toggleShow"
            >
                🔔
                <template #badge>
                    {{ notificationNumber }}
                </template>
            </vs-avatar>
        </vs-tooltip>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
    name: "BellNotifications",
    data: () => ({ show: false }),
    computed: {
        ...mapState("notifications", ["notifications"]),
        notificationNumber() {
            return this.notifications.filter((item) => !item.checkedAt).length;
        }
    },
    methods: {
        ...mapActions("notifications", ["verifyNotification"]),
        async routePush(id, path) {
            this.show = false;
            await Promise.race([
                this.verifyNotification({ id }),
                // eslint-disable-next-line no-unused-vars
                new Promise((resolve, _reject) => {
                    setTimeout(() => {
                        this.$router.push(path);
                        resolve();
                    }, 300);
                })
            ]);
        },
        toggleShow() {
            this.show = !this.show;
        }
    }
};
</script>
