<template>
    <div>
        <vs-tooltip
            v-show="accessToken"
            v-model="show"
            bottom
            shadow
            interactivity
            border-thick
        >
            <vs-avatar
                pointer
                size="40"
                circle
                history
                primary
                @click="toggleShow"
            >
                <img
                    v-if="user.picture"
                    :src="user.picture"
                    alt=""
                    referrerpolicy="no-referrer"
                />
                <span v-else> 😺 </span>
            </vs-avatar>
            <template #tooltip>
                <div class="d-flex flex-md-column align-items-start py-3">
                    <div class="m-md-2 m-1">
                        🟢
                        <button
                            class="font-weight-bold btn-a ml-md-2 ml-1"
                            aria-label="Ir a inicio de app"
                            @click="routePush('/app')"
                        >
                            App
                        </button>
                    </div>
                    <div class="m-md-2 m-1">
                        🔵
                        <button
                            class="font-weight-bold btn-a ml-md-2 ml-1"
                            aria-label="Ir a perfil"
                            @click="routePush('/app/profile')"
                        >
                            Perfil
                        </button>
                    </div>
                    <div class="m-md-2 m-1">
                        🟣
                        <button
                            class="font-weight-bold btn-a ml-md-2 ml-1"
                            aria-label="cerrar sesión"
                            @click="logout"
                        >
                            Salir
                        </button>
                    </div>
                </div>
            </template>
        </vs-tooltip>
        <div v-show="!accessToken">
            <div class="d-flex">
                <vs-button border>
                    <router-link
                        class="font-weight-bold"
                        rel="Incia sesión"
                        to="/login"
                        aria-label="Ir a inicio de sesión"
                    >
                        Inicia Sesión
                    </router-link>
                </vs-button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

export default {
    name: "OptionsUser",
    data: () => ({
        show: false
    }),
    computed: {
        ...mapState("auth", ["accessToken"]),
        ...mapState("auth", ["user"]),
        ...mapGetters("control", ["backgroundColor"])
    },
    watch: {
        show(val) {
            if (val) setTimeout(() => (this.show = false), 40000);
        }
    },
    mounted() {},
    methods: {
        ...mapActions("auth", ["signOut"]),
        ...mapMutations("control", ["CLEAR_SIDEBAR"]),
        ...mapMutations("commons", ["CLEAR_MERCHANTS"]),
        async logout() {
            const dialogResult = await this.$swal.fire({
                background: this.backgroundColor,
                title: "¿ Deseas cerrar tu sesión ?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#ee3f59",
                cancelButtonColor: "#02913f",
                confirmButtonText: "Si",
                cancelButtonText: "No"
            });

            if (dialogResult.isConfirmed) {
                this.signOut();
                this.$router.push("/login");
            }
        },
        async notification() {
            if (
                !("Notification" in window) ||
                !("serviceWorker" in navigator)
            ) {
                return console.log("Tu browser no soporta notificaciones");
            }

            if (Notification.permission === "default") {
                await Notification.requestPermission();
            }

            if (Notification.permission === "blocked") {
                return console.log("Bloqueaste las notificaciones :(");
            }

            if (Notification.permission !== "granted") {
                return console.log("Notificaciones no habilitadas");
            }

            const registration =
                await navigator.serviceWorker.getRegistration();
            if (!registration)
                return console.log("No hay un Service Worker :(");
            registration.showNotification("Cerraste tu sesió", {
                body: "Te esperamos pronto",
                img: "/img/icons/apple-touch-icon-114x114.png",
                vibrate: [1000, 500, 1000]
            });
        },
        routePush(path) {
            this.show = false;
            return this.$router.push(path);
        },
        toggleShow() {
            this.show = !this.show;
        }
    }
};
</script>
