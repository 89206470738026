<template>
    <main id="app" class="h-screen w-screen">
        <navbar-app />

        <div id="app-container" class="container pt-5">
            <div class="col-12 h-20"></div>
            <sidebar-app />
            <router-view></router-view>
            <div class="col-12 h-20"></div>

            <div class="config p-2 border-top border-left border-success">
                <change-theme />
            </div>
        </div>
        <footer-app />
    </main>
</template>

<script>
import ChangeTheme from "@/components/layout/ChangeTheme.vue";
import FooterApp from "@/components/layout/FooterApp.vue";
import NavbarApp from "@/components/layout/NavbarApp.vue";
import SidebarApp from "@/components/layout/SidebarApp.vue";

export default {
    name: "LayoutApp",
    components: {
        FooterApp,
        SidebarApp,
        NavbarApp,
        ChangeTheme
    },
    created() {
        this.$watch(
            () => this.$route,
            () => {
                document
                    .getElementById("app-container")
                    .scrollIntoView({ behavior: "smooth" });
            },
            {
                deep: true
            }
        );
    }
};
</script>
