<template>
    <footer class="footer w-100 mt-5 py-3">
        <div
            class="d-flex flex-column align-items-center justify-content-center"
        >
            <span>
                <router-link to="/app" aria-label="Ir a inicio">
                    🏠
                </router-link>
                &copy; Innovalombia
            </span>

            <div>
                <a
                    :href="$data.$siteUrl"
                    target="_blank"
                    rel="noopener"
                    class="h-lg-4 h-5"
                >
                    {{ $siteTitle }}
                </a>
                © {{ new Date().getFullYear() }}
            </div>
        </div>
    </footer>
</template>

<script>
import { MARKETPLACE_PORTAL_URL } from "@/common/constants";

export default {
    props: {
        additionalClass: {
            type: String,
            default: () => ""
        }
    },
    data: () => ({
        $siteUrl: MARKETPLACE_PORTAL_URL
    })
};
</script>
