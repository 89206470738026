<template>
    <div v-if="$ability.hasScope('merchant:ListOwn')">
        <div
            v-if="selectedMerchant && selectedMerchant.merchantId"
            class="d-flex align-items-center"
        >
            <vs-avatar
                pointer
                color="#02913f"
                size="40"
                @click="openMerchantModalSelector"
            >
                {{ selectedMerchant.merchantType | merchantInfo("icon") }}
            </vs-avatar>
            <div class="ml-2 d-flex flex-column">
                <strong> {{ selectedMerchant.name }}</strong>
                <small>
                    {{ selectedMerchant.address | address | cut(16) }}
                </small>
            </div>
        </div>
        <div v-else>
            <vs-button @click="openMerchantModalSelector">
                No has seleccionado un comercio
            </vs-button>
        </div>
        <vs-dialog
            v-model="isOpenModal"
            overflow-hidden
            blur
            not-close
            :loading="isLoading"
        >
            <template #header>
                <div
                    class="d-flex justify-content-center align-items-center flex-md-row flex-column mt-2"
                >
                    <h4 class="text-center w-flex mx-3">
                        Seleccione un comercio
                    </h4>
                    <vs-button
                        circle
                        icon
                        floating
                        class="my-0"
                        @click="reloadItems"
                    >
                        <reload-icon />
                    </vs-button>
                </div>
            </template>
            <div class="con-content">
                <div class="col-12 col-mx-10 mx-auto">
                    <span>
                        Seleccione el comercio en donde va a registrar sus
                        operaciones (Registro de ventas, inventarios).
                    </span>
                    <span v-if="errorMessage" class="text-danger">
                        {{ errorMessage }}
                    </span>
                    <div
                        v-if="merchantsOwn.length"
                        class="d-flex flex-column mt-3 justify-content-center align-items-center w-100"
                    >
                        <div
                            v-for="item in merchantsOwn"
                            :key="`${item.merchantType}:${item.merchantId}`"
                            class="position-relative w-lg-75 w-100 mx-auto"
                        >
                            <button
                                class="d-flex flex-column flex-lg-row justify-content-between btn btn-primary w-100 my-2 text-left"
                                :class="
                                    selectedMerchant.merchantId ===
                                        item.merchantId &&
                                    selectedMerchant.merchantType ===
                                        item.merchantType
                                        ? 'border-3 border-highlight'
                                        : ''
                                "
                                @click="selectionMerchant(item)"
                            >
                                <div class="d-flex flex-column">
                                    <h3>
                                        {{
                                            item.merchantType
                                                | merchantInfo("icon")
                                        }}
                                        {{ item.name }}
                                    </h3>
                                    <p>🗺 {{ item.address }}</p>
                                </div>
                                <div class="d-flex flex-column">
                                    <router-link
                                        class="text-white"
                                        to="/app/merchant/box"
                                    >
                                        Efectivo &nbsp;&nbsp;&nbsp;&nbsp; 💰
                                        {{ item.moneyConfig.cash | money }}
                                    </router-link>

                                    <router-link
                                        class="text-white"
                                        to="/app/merchant/box"
                                    >
                                        Nequi
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        📳
                                        {{ item.moneyConfig.nequi | money }}
                                    </router-link>

                                    <router-link
                                        class="text-white"
                                        to="/app/merchant/box"
                                    >
                                        Daviplata &nbsp; 📲
                                        {{ item.moneyConfig.daviplata | money }}
                                    </router-link>

                                    <router-link
                                        class="text-white"
                                        to="/app/merchant/box"
                                    >
                                        Datáfono &nbsp; 💳
                                        {{ item.moneyConfig.terminal | money }}
                                    </router-link>
                                </div>
                            </button>
                            <button
                                class="position-absolute bottom-0 right-0 border border-secondary badge rounded-pill bg-warning p-1"
                                :aria-label="`Ir a comercio ${item.name}`"
                                @click="
                                    redirectTo(
                                        `/app/${item.merchantType}s/reduced/${item.merchantId}`
                                    )
                                "
                            >
                                <span> 👀 </span>
                            </button>
                        </div>
                    </div>
                    <div v-else class="col-11 mx-auto mt-4">
                        <strong> No tienes comercioes asociados </strong>
                    </div>
                </div>
            </div>
        </vs-dialog>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

import ReloadIcon from "@/components/icons/ReloadIcon.vue";

export default {
    name: "MerchantModalSelector",
    components: { ReloadIcon },
    data: () => ({ isOpenModal: false, isLoading: false, errorMessage: "" }),
    computed: {
        ...mapState("commons", ["merchantsOwn", "selectedMerchant"])
    },
    async mounted() {
        this.setInput();
    },
    methods: {
        ...mapActions("commons", ["listOwnMerchants"]),
        ...mapActions("auth", ["applyMerchantScopes"]),
        ...mapMutations("commons", ["SET_MERCHANT_SELECTED"]),
        openMerchantModalSelector() {
            this.isOpenModal = true;
        },
        async reloadItems() {
            await this.listItems(true);
        },
        async listItems(required = false) {
            this.isLoading = true;
            try {
                await this.listOwnMerchants({
                    required
                });
            } catch (error) {
                this.errorMessage = error.message;
                console.error({
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.isLoading = false;
            }
            if (required && this.selectedMerchant.merchantId) {
                this.selectionMerchant(this.selectedMerchant);
            }
        },
        setInput() {
            const isOwnerSelectStore = this.merchantsOwn.some(
                (item) => item.id === this.selectedMerchant.merchantId
            );
            if (this.merchantsOwn.length > 0 && !isOwnerSelectStore) {
                this.selectionMerchant(this.merchantsOwn[0]);
            }
        },
        selectionMerchant({ merchantId = null, merchantType }) {
            this.isOpenModal = false;
            const selectMerchant = this.merchantsOwn.find(
                (item) =>
                    item.merchantId === merchantId &&
                    item.merchantType === merchantType
            );
            this.SET_MERCHANT_SELECTED(selectMerchant);
            this.applyMerchantScopes(
                selectMerchant.scopes.map((item) => item.slug)
            );
        },
        redirectTo(url) {
            this.isOpenModal = false;
            this.$router.push(url);
        }
    }
};
</script>
